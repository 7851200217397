import axios from 'axios'
import moment from 'moment'

const http = axios.create()
http.defaults.xsrfCookieName = 'csrftoken'
http.defaults.xsrfHeaderName = 'X-CSRFToken'

function handleError(error) {
    const errors = []
    if (error.response.status < 500) {
        if (Array.isArray(error.response.data)) {
            error.response.data.forEach(e => errors.push(e))
        } else if (typeof error.response.data === 'object') {
            Object.keys(error.response.data).forEach(key => {
                let errorMessage = `${key}: ${error.response.data[key]}`
                if (typeof error.response.data[key] === 'object') {
                    errorMessage = `${key}: ${error.response.data[key].join()}`
                }
                errors.push(errorMessage)
            })
        } else {
            errors.push('Invalid request.')
        }
    } else if (error.response.status >= 500) {
        errors.push('Server error. Contact administrator.')
    } else if (error.request) {
        errors.push('Server error, no response. Try again later.')
    } else {
        errors.push('Request not sent. Contact administrator.')
    }

    return errors.join(' ')
}

export default {
    install(Vue) {
        Vue.prototype._apiBind = function () {
            return {
                getProductionProcess: async (production_date__gte = moment().subtract(7, 'd').format('YYYY-MM-DD')) => {
                    try {
                        const {data} = await http.get('/api/manufacturing/productionprocess/', {
                            params: {
                                production_date__gte
                            }
                        })

                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return []
                    }
                },
                getProductionReservationProcess: async (customerId) => {
                    try {
                        const {data} = await http.get('/api/manufacturing/productionprocess/reservations/', {
                            customer_id: customerId
                        })
                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return []
                    }
                },
                getProductionReservationProcessToClipboard: async (production_processes_ids, company_id) => {
                    try {
                        const {data} = await http.post('/api/manufacturing/productionprocess/to_clipboard/', {
                            production_processes_ids: production_processes_ids,
                            company_id: company_id,
                        })
                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return []
                    }
                },

                getCarouselSetupDetailed: async (start_date, end_date) => {
                    try {
                        const {data} = await http.get('/api/manufacturing/carouselsetupdetailed/', {
                            params: {
                                start_date,
                                end_date
                            }
                        })

                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return []
                    }
                },

                getCarouselSetup: async (start_date, end_date) => {
                    try {
                        const {data} = await http.get('/api/manufacturing/carouselsetup/', {
                            params: {
                                start_date,
                                end_date
                            }
                        })

                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return []
                    }
                },

                getSekaciPlans: async (filter) => {
                    try {
                        const {data} = await http.get('/api/manufacturing/sekaciplan/', {
                            params: filter
                        })

                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return []
                    }
                },
                getSekaciPlanColorHistory: async (filter) => {
                    try {
                        const {data} = await http.get('/api/manufacturing/sekaciplan/materials_color_history/', {
                            params: filter
                        })

                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return []
                    }
                },
                getSekaciPlansMaterials: async (payload) => {
                    try {
                        const {data} = await http.post('/api/manufacturing/sekaciplan/materials/', payload)

                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return []
                    }
                },
                revertSekaciPlanColorHistory: async (payload) => {
                    try {
                        const {data} = await http.post('/api/manufacturing/sekaciplan/materials_color_history_revert/', payload)

                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return []
                    }
                },
                updateSekaciPlansMaterialsColor: async (payload) => {
                    try {
                        const {data} = await http.post('/api/manufacturing/sekaciplan/materials_color/', payload)

                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return []
                    }
                },
                getSekaciPlan: async (id) => {
                    try {
                        const {data} = await http.get(`/api/manufacturing/sekaciplanprocess/${id}/`)

                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return []
                    }
                },

                updateSekaciPlanOperation: async (sekaci_plan_id, operation) => {
                    let payload = {operation}
                    try {
                        const {data} = await http.patch(`/api/manufacturing/sekaciplan/operation/${sekaci_plan_id}/`, payload)
                        this.$api.consumeMessages();
                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return {}
                    }
                },
                addProductionProcess: async (setup_id, productionprocess_id) => {
                    try {
                        const {data} = await http.post(`/api/manufacturing/carouselsetupdetailed/${setup_id}/add_production_process/`, {
                            productionprocess_id
                        })

                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return {}
                    }
                },

                unassignFromSetup: async (productionprocess_id) => {
                    try {
                        const {data} = await http.post(`/api/manufacturing/productionprocess/${productionprocess_id}/unassign_carousel_setup/`)

                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return {}
                    }
                },

                generateFromProductionProcess: async (productionprocess_id, date) => {
                    try {
                        const {data} = await http.post(`/api/manufacturing/carouselsetupdetailed/create_from_production_process/`, {
                            productionprocess_id,
                            date
                        })

                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return {}
                    }
                },
                getCompaniesByName: async (company_name) => {
                    try {
                        const {data} = await http.get('/api/customers/companies/', {
                            params: {
                                company_name__icontains: company_name
                            }
                        });

                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return []
                    }
                },
                getCompaniesById: async (id) => {
                    try {
                        const {data} = await http.get(`/api/customers/companies/${id}`);
                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return []
                    }
                },

                getManufacturingVariants: async (filter) => {
                    try {
                        const {data} = await http.get('/api/manufacturing/productvariant/with_material_lines/', {
                            params: filter
                        })

                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return []
                    }
                },

                deleteProductionProcess: async (id) => {
                    try {
                        const {data} = await http.delete(`/api/manufacturing/carouselsetupdetailed/${id}/`)

                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return {}
                    }
                },

                moveUp: async (id) => {
                    try {
                        const {data} = await http.post(`/api/manufacturing/carouselsetupdetailed/${id}/move_up/`)
                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return {}
                    }
                },

                moveDown: async (id) => {
                    try {
                        const {data} = await http.post(`/api/manufacturing/carouselsetupdetailed/${id}/move_down/`)

                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return {}
                    }
                },

                updateProductionProcess: async (id, payload) => {
                    try {
                        const {data} = await http.patch(`/api/manufacturing/productionprocess/${id}/`, payload)

                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return {}
                    }
                },

                getMouldTypes: async () => {
                    try {
                        const {data} = await http.get('/api/moulds/')

                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return []
                    }
                },

                getMouldColors: async () => {
                    try {
                        const {data} = await http.get('/api/mould_colors/')

                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return []
                    }
                },

                consumeMessages: async () => {
                    try {
                        const {data} = await http.get('/api/messages/')
                        data.forEach(m => {
                            this.$message({
                                message: m.message,
                                type: m.type
                            });
                        })
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return []
                    }
                },

                getSampleById: async (id) => {
                    try {
                        const {data} = await http.get(`/api/products/${id}/sample`)

                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return []
                    }
                },

                confirmSamplesOrder: async (customerId, samples) => {
                    try {
                        const {data} = await http.post(`/api/order/orders/samples/`, {
                            customer_id: customerId,
                            samples: samples,
                        })

                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return {}
                    }
                },
                confirmReservationOrder: async (customerId, productionProcessId, assortmentRepetitions) => {
                    try {
                        const {data} = await http.post(`/api/order/orders/make_reservation/`, {
                            customer_id: customerId,
                            production_process_id: productionProcessId,
                            assortment_repetitions: assortmentRepetitions,
                        })

                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return {}
                    }
                },

                getOrderReservations: async () => {
                    try {
                        const {data} = await http.get('/api/order/orderitem/reservations/', {})
                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return []
                    }
                },

                getBalenieFilterTags: async() => {
                    try {
                        const {data} = await http.get('/api/manufacturing/baleniefiltertags/', {})
                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return []
                    }
                },

                createBalenieFilterTag: async(slug) => {
                    try {
                        const {data} = await http.post('/api/manufacturing/baleniefiltertags/', {
                            slug: slug
                        })
                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return e
                    }
                },

                deleteBalenieFilterTag: async(slug) => {
                    try {
                        const {data} = await http.delete(`/api/manufacturing/baleniefiltertags/${slug}`)
                        return data
                    } catch (e) {
                        this.$handleError(handleError(e))
                        return e
                    }
                }
            }
        }

        Vue.prototype.$api = Vue.prototype._apiBind()
    }
}